import React, { useState } from 'react';
import "./Contact.css";
import { contact } from "../../images/index"

const Contact = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        contact: "",
        message: ""
    });

    // const navigate = useNavigate();

    function handleChange(e) {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        // Replace with the URL of your Google Apps Script web app
        const scriptURL = "https://script.google.com/macros/s/AKfycbz7biT8mP_yJqrNQcmf2EjU2YlBg9aKB30oU_b-EB2yoLQYCVR41FzcqhKYK0SsjIF_/exec";

        // Send the form data to the server using an AJAX request
        fetch(scriptURL, {
            method: "POST",
            body: new FormData(e.target)
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (text) {
                // Display the success message
                alert("Form submitted successfully!");
            });
    }

    return (
        <section className="contact">
            <div className="cform">
                <h2>Contact Us</h2>
                <p>We would love to hear from you! Reach out to us with any questions, inquiries, or feedback you may have.</p>
                <form onSubmit={handleSubmit}>
                    <input type="text" value={formData.name}
                        onChange={handleChange} name='name'
                        placeholder='Full Name' />
                    <input type="tel" value={formData.contact}
                        onChange={handleChange} name='contact'
                        placeholder='Phone No' />
                    <input type="email" value={formData.email}
                        onChange={handleChange} name='email'
                        placeholder='Email' />
                    <textarea name="message" value={formData.message}
                        onChange={handleChange}
                        rows="6" placeholder='Message' />
                    <input className='btn' type="submit" />

                </form>
            </div>
            <div className="cimg">
                <img src={contact} alt="" />
            </div>
        </section>
    )
}

export default Contact