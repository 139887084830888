import { useState } from "react";
import Testimonial from "../Testimonial/Testimonial"
import "./TestimonialSlider.css"
import { BsFillArrowLeftSquareFill, BsFillArrowRightSquareFill } from "react-icons/bs";


const TestimonialSlider = () => {
    // A state variable to keep track of the current index
    const [index, setIndex] = useState(0);

    const testimonials = [
        {
            text: "This is the best product ever! It has changed my life for the better.",
            name: "John Doe",
        },
        {
            text: "I am very satisfied with the service and support. They are very responsive and helpful.",
            name: "Jane Smith",
        },
        {
            text: "I love the design and functionality of this product. It is very easy to use and looks great.",
            name: "Jack Lee",
        },
        {
            text: "This is a very reliable and trustworthy company. They deliver on their promises and exceed my expectations.",
            name: "Mary Chen",
        },
    ];

    // A function to handle the left button click
    const handleLeftClick = () => {
        // Decrease the index by one, or wrap around to the last index
        setIndex((index - 1 + testimonials.length) % testimonials.length);
    };

    // A function to handle the right button click
    const handleRightClick = () => {
        // Increase the index by one, or wrap around to the first index
        setIndex((index + 1) % testimonials.length);
    };

    return (
        <div className="testimonial-slider">

            <Testimonial text={testimonials.text} name={testimonials.name} {...testimonials[index]} />


            <div>
                <span className="left-button" onClick={handleLeftClick}><BsFillArrowLeftSquareFill /></span>
                <span className="right-button" onClick={handleRightClick}><BsFillArrowRightSquareFill /></span>
            </div>


        </div>
    );
};

export default TestimonialSlider;