import React from 'react'
import Sectionhead from '../Sectionhead/Sectionhead';
import { about } from "../../images/index";
import "./About.css"
import { AiOutlinePlus, AiOutlineCheck, AiFillThunderbolt } from "react-icons/ai";

const About = () => {
  return (
    <section className="about">
      <Sectionhead title="About Us" para1="STOCKMINER is a leading educational institution that specializes in financial trading education."
        para2="With a focus on empowering our Traders with the knowledge and skills necessary to succeed in the financial markets, we strive to deliver high-quality education and mentorship."
        para3="Join us on our journey as we equip aspiring traders with the tools and strategies needed to navigate the dynamic landscape of trading and achieve their financial goals." img={about} />

      <div className="asec2">
        <div>
          <h3>Our Vision</h3>

          <p>To empower individuals with the knowledge and skills to confidently participate in the financial markets and achieve financial independence.</p>

          <p>We envision a future where anyone can access quality trading education and have the opportunity to build a successful trading career.</p>
        </div>


        <div>
          <h3>Our Mission</h3>

          <p>Our mission is to deliver comprehensive and practical trading education that equips individuals with the necessary skills to navigate the financial markets successfully.</p>

          <p>We are committed to providing a supportive learning environment, leveraging industry expertise, and fostering a community of traders dedicated to continuous growth and improvement.</p>
        </div>

      </div>

      <div className="asec3">
        <div>
          <AiOutlinePlus />
          <h3>Quality</h3>
          <p>We strive to deliver the highest quality trading education and mentorship to our Traders.</p>
        </div>

        <div>
          <AiOutlineCheck />
          <h3>Innovation</h3>
          <p>We embrace innovation and continuously explore new strategies, technologies, and market trends to enhance our educational offerings.</p>
        </div>

        <div>
          <AiFillThunderbolt />
          <h3>Trader Success</h3>
          <p>We are committed to the success of our traders and provide ongoing support, mentorship, and resources to help them achieve their trading goals.</p>
        </div>
      </div>
    </section>
  )
}

export default About