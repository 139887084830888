import React, { useState } from 'react';
import { course } from "../../images/index";
import "./Coursecard.css";
import { HiX } from "react-icons/hi";

const Coursecard = ({ coursee }) => {
  const [open, setOpen] = useState(true);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <>
      <div className={open ? 'modal' : 'modal open'}>
        <span onClick={handleClick}> <HiX /></span>
        <div className="content">
          <p>{coursee.name}</p>
          <ul>
            {coursee.feature.map((item) => (
              <li key={Math.random()}>{item}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="coursecard">
        <img src={course} alt="course img" />
        <div className="bottom">
          <p>{coursee.name}</p>
          <span onClick={handleClick}>Syllabus</span>
        </div>
      </div>
    </>
  );
};

export default Coursecard;
