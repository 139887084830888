import React from 'react';
import "./Infosec.css"

const Infosec = ({ classes, img, heading, para }) => {
    return (
        <section className={classes}>
            <div className="heroimg">
                <img src={img} alt="" />
            </div>
            <div className="herotext">
                <h3>{heading}</h3>
                <p>{para}</p>
            </div>
        </section>
    )
}

export default Infosec