import React from 'react';
import "./Sectionhead.css"

const Sectionhead = ({ title, para1, para2, para3, img }) => {
    return (
        <section className="shead">

            <div className="scontent">
                <h2>{title}</h2>
                <p>{para1}</p>
                <p>{para2}</p>
                <p>{para3}</p>
            </div>
            <div className="simg">
                <img src={img} alt="" />
            </div>

        </section>
    )
}

export default Sectionhead