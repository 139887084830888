export const hlogo = require("./hlogo.png");
export const flogo = require("./flogo.png");
export const course = require("./course.jpg");
export const info1 = require("./infosec1.jpg");
export const info2 = require("./infosec2.jpg");
export const info3 = require("./infosec3.jpg");
export const info4 = require("./infosec4.jpg");
export const info5 = require("./infosec5.jpg");
export const about = require("./about.png");
export const courses = require("./course.png");
export const contact = require("./contact.png");
