import React from 'react'
import Accordion from '../Accordion/Accordion';
import "./Faqs.css"

const Faqs = () => {

    const data = [
        {
            title: 'Is the Ultimate Mentorship Program suitable for beginners?',
            content: 'Yes, the Ultimate Mentorship Program is designed to cater to individuals who are new to the stock market. We provide comprehensive training from the basics of technical analysis to advanced trading strategies, allowing beginners to learn and generate consistent income from trading.'
        },

        {
            title: 'Will the sessions be conducted in both Hindi and English?',
            content: 'Yes, the sessions will be conducted in both Hindi and English.'
        },

        {
            title: 'Will there be live market sessions in the program?',
            content: 'Yes, we understand the importance of live market sessions in trading education. The Ultimate Mentorship Program includes a total of 10 live market sessions conducted during morning hours to provide real-time trading experience.'
        },

        {
            title: 'What if I miss any live session?',
            content: 'If you miss a live session, you can repeat it twice to cover any missed content'
        },
        {
            title: 'How much capital do I need to trade after learning your process?',
            content: 'Capital is not a major concern. We advise every student to start with a small capital and practice the setups taught in our sessions. Once you achieve consistency and generate small profits, you can gradually scale up by reinvesting the profits. Our focus is on teaching you to make money with any capital size.'
        },
        {
            title: 'What topics are covered in the Basic course?',
            content: 'The Basic course covers topics such as the stock market, different trading styles, market phases, candlesticks, risk management, position sizing, price action, entry and exit strategies, and market psychology.'
        },
        {
            title: 'What topics are covered in the Advanced course?',
            content: 'The Advanced course covers topics such as the equity market vs. derivative market, risks involved in the derivative market, players in the derivative market, expiry of futures and options contracts, option chain analysis, stoploss hunting, margin trading, and options Greeks.'
        },
    ]
    return (
        <section className="faq">


            {data.map((data) => {
                return <Accordion key={Math.random()} title={data.title} content={data.content} />
            })}


        </section>
    )
}

export default Faqs