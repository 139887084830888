import React from 'react'
import Sectionhead from '../Sectionhead/Sectionhead';
import Course from '../Course/Course';
import { courses } from "../../images/index";
import "./Courses.css"

const Courses = () => {
  return (
    <section className="courses">
      <Sectionhead title="Courses"
        para1="At STOCKMINER we are dedicated to providing high-quality trading courses for beginners and experienced traders alike."
        para2="Whether you are interested in learning the fundamentals of trading or want to enhance your skills in advanced trading strategies, our courses are designed to help you succeed."
        para3="We offer comprehensive courses that cover various asset classes, including stocks. Our curriculum includes practical exercises, real-world examples, and ongoing support to ensure your learning journey is engaging and rewarding." img={courses} />
      <h2>Courses</h2>
      <Course />

    </section>
  )
}

export default Courses