import React from 'react';
import { flogo } from "../../images/index";
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import "./Footer.css"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo">
        <img src={flogo} alt="" />
        <p>By connecting students all over the world to the best instructors, we are helping individuals reach their goals and pursue their dreams.</p>

        <div className="social">
          <BsFacebook />
          <BsInstagram />
          <BsTwitter />
          <BsYoutube />
        </div>

      </div>
      <div className="links">
        <h2>Quick Links</h2>
        <ul>
          <Link to="/about">About</Link>
          <Link to="/about">Courses</Link>
          <Link to="/about">Testimonial</Link>
          <Link to="/about">Contact</Link>
        </ul>

      </div>
      <div className="fcontact">
        <h2>Contact</h2>
        <p>
          301, A Wing, <br /> Emerald High, Bhandup (W), <br /> Mumbai-400088
        </p>

        <a href="/#"> 9076384105</a> <br />
        <a href="/#">stockminersteam@gmail.com</a> 

      </div>
    </footer>
  )
}

export default Footer