import React from 'react';
import "./Course.css"
import Coursecard from '../Coursecard/Coursecard';

const Course = () => {

    const course = {

        basics: {
            name: 'Basic Course',
            feature: [
                "What is stock market.",
                "Benefits of trading",
                "Different style of trading",
                "Understanding market phases",
                "Tools required for trading",
                "All about candlesticks",
                "Important market pattern",
                "Risk management",
                "Position sizing",
                "Price action",
                "Entry exit strategy",
                "Market psychology",
            ]
        },

        advance: {
            name: 'Super Trader Course',
            feature: [
                "Understanding market phase",
                "Trading in different market",
                "Charting essential",
                "Candlesticks and pattern",
                "Understanding trends",
                "Breakout stops and retracement",
                "Classic chart pattern",
                "Cpr guide",
                "Indicators and confirmation",
                "Trading psychology",
                "Risk management",
                "Future and options",
            ]
        }
    }






    return (
        <section className="coursecon">

            <div className="course">
                <Coursecard coursee={course.basics} />
                <Coursecard coursee={course.advance} />

            </div>

        </section>
    )
}

export default Course