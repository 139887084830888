
import React from "react";
import "./Testimonial.css"


// A sample array of testimonials


// A custom component to display a single testimonial
const Testimonial = ({ text, name }) => {
  return (
    <div className="testimonial">
      <p className="testimonial-text">{text}</p>
      <p className="testimonial-name">- <strong>{name}</strong></p>
    </div>
  );
};


export default Testimonial;