import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { hlogo } from "../../images/index"
import "./Header.css"
import { HiMenuAlt1, HiX } from "react-icons/hi";

const Header = () => {

  const [open, setOpen] = useState(false)

  function handleOpen() {
    setOpen(!open)
  }

  function handleScroll() {
    setOpen(false)
    window.scroll(0,0)
  }




  return (
    <header className="header">

      <div className="logo">
        <Link to="/"><img src={hlogo} alt="logo" /></Link>
      </div>

      <nav className={open ? "nav open" : "nav"}>
        <HiX onClick={handleOpen} className='cross' />
        <Link onClick={handleScroll} to='/'>Home</Link>
        <Link onClick={handleScroll} to='/about'>About</Link>
        <Link onClick={handleScroll} to='/course'>Courses</Link>
        <Link onClick={handleScroll} to='/testimonial'>Testimonial</Link>
        <Link onClick={handleScroll} className='btn' to='/contact'>Contact</Link>
      </nav>

      <HiMenuAlt1 onClick={handleOpen} className='menu' />

    </header>
  )
}

export default Header