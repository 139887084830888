import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from "./component/Header/Header"
import Footer from "./component/Footer/Footer"
import Home from "./component/Home/Home"
import About from "./component/About/About"
import Contact from "./component/Contact/Contact"
import Courses from "./component/Courses/Courses"
// import Testimonial from "./component/TestimonialSlider/TestimonialSlider"
import TestimonialSlider from './component/TestimonialSlider/TestimonialSlider';

function App() {
  return (
    <div className="App">

      <Header />
      <div className="space"></div>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/course' element={<Courses />} />
        <Route path='/testimonial' element={<TestimonialSlider />} />
      </Routes>






      <Footer />

    </div>
  );
}

export default App;
