import React from 'react'
import "./Home.css"
import Course from '../Course/Course'
import Infosec from '../Infosec/Infosec'
import TestimonialSlider from "../TestimonialSlider/TestimonialSlider"

import { info1, info2, info3, info4, info5 } from "../../images/index";
import Faqs from "../Faq/Faqs"
import Homeimg from "../../images/svg4.svg"
import Homebot from "../../images/svg3.svg"


const Home = () => {


    const data = [
        {
            img: info1,
            head: "Live Trading Sessions",
            para: 'Experience real-world trading through our live sessions where our expert traders analyze the markets and share their proven strategies.',
            classes: 'infosec'
        },

        {
            img: info2,
            head: "Cutting-Edge Tools",
            para: 'Access our suite of advanced trading tools and resources to make informed decisions and stay ahead of the market competition.',
            classes: 'infosec ireverse'
        },

        {
            img: info3,
            head: "Expert Guidance",
            para: 'Our team of experienced traders and mentors provide personalized guidance and support to help you achieve your trading goals.',
            classes: 'infosec'
        },

        {
            img: info4,
            head: "Engaging Community",
            para: 'Join our vibrant community of traders to collaborate, share insights, and foster mutual growth in a supportive environment.',
            classes: 'infosec ireverse'
        },

        {
            img: info5,
            head: "Comprehensive Education",
            para: 'We provide a comprehensive curriculum covering various aspects of trading, including technical analysis, fundamental analysis, risk management, and trading psychology.',
            classes: 'infosec'
        },

    ]



    return (

        <div className='container'>
            <section className="home">
                <div className="stocks">
                    <iframe
                        title="Embedded Video"
                        src="https://s.tradingview.com/embed-widget/ticker-tape/?locale=in#%7B%22symbols%22%3A%5B%7B%22description%22%3A%22SENSEX%22%2C%22proName%22%3A%22INDEX%3ASENSEX%22%7D%2C%7B%22description%22%3A%22SBIN%22%2C%22proName%22%3A%22BSE%3ASBIN%22%7D%2C%7B%22description%22%3A%22ADANI%20ENT%22%2C%22proName%22%3A%22BSE%3AADANIENT%22%7D%2C%7B%22description%22%3A%22ICICI%20BANK%22%2C%22proName%22%3A%22BSE%3AICICIBANK%22%7D%2C%7B%22description%22%3A%22TCS%22%2C%22proName%22%3A%22BSE%3ATCS%22%7D%2C%7B%22description%22%3A%22HDFC%20BANK%22%2C%22proName%22%3A%22BSE%3AHDFCBANK%22%7D%2C%7B%22description%22%3A%22RELIANCE%22%2C%22proName%22%3A%22BSE%3ARELIANCE%22%7D%2C%7B%22description%22%3A%22INFY%22%2C%22proName%22%3A%22BSE%3AINFY%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22regular%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22tradingcafeindia.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22tradingcafeindia.com%2F%22%7D"
                        width="100%"
                        height="55px"
                        allowFullScreen
                    />
                </div>

                <div className="hcon">
                    <div className="hcontent">
                        <h1>Learn to Trade in <br /> <span>Correct</span> way</h1>
                        <a href="https://www.google.com">Download Brochure</a>
                        <img src={Homebot} alt="home bottom img" />
                    </div>
                    <div className="hmedia">
                        <img src={Homeimg} alt="home main" />
                    </div>
                </div>
            </section>

            <h2>Why Us ?</h2>


            {
                data.map((data) => {
                    return <Infosec key={Math.random()} img={data.img} classes={data.classes} heading={data.head} para={data.para} />
                })
            }


            <h2>Courses</h2>

            <Course />

            <h2>Testimonial</h2>

            <TestimonialSlider />


            <h2>Faq</h2>

            <Faqs />










        </div>
    )
}

export default Home